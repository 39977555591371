<template>
  <v-main class="mt-16">
    <section id="blog">
      <section id="hero">
        <div class="v-sheet v-sheet--tile theme--dark">
          <div class="v-responsive v-image" style="height: 40vh">
            <div
              class="v-responsive__sizer"
              style="padding-bottom: 66.6667%"
            ></div>
            <div
              class="v-image__image v-image__image--cover"
              style="
                background-image: linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0.62),
                    rgba(0, 0, 0, 0.62)
                  ),
                  url('https://material-landing.vuetifyjs.com/img/blog-bg.541c7033.jpg');
                background-position: center center;
              "
            ></div>
            <div class="v-responsive__content" style="width: 1950px">
              <div class="container fill-height px-4" style="padding: 96px 0px">
                <div class="row align-center justify-center">
                  <h1 class="font-weight-bold display-3">This is our Blog</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="posts">
        <div
          data-v-3e9e5b5a=""
          class="
            mx-auto
            py-6
            mb-2
            px-4
            v-card v-sheet
            theme--light
            elevation-24
          "
          style="max-width: 95%; margin-top: -64px"
        >
          <div
            data-v-3e9e5b5a=""
            class="mx-auto py-0 py-md-6 v-sheet theme--light"
            style="max-width: 1000px"
          >
            <!---->
            <h2 class="display-2 font-weight-light secondary--text mb-4">
              Demo Post
            </h2>
            <div class="v-sheet theme--light post--dense">
              <div class="container pa-0">
                <div class="row mb-4">
                  <div class="col">
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-calendar theme--light"
                        style="font-size: 16px"
                      ></i>
                      December 22, 2018
                    </div>
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-account theme--light"
                        style="font-size: 16px"
                      ></i>
                      John Leider
                    </div>
                    <div>
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-chat theme--light"
                        style="font-size: 16px"
                      ></i>
                      Leave a Comment
                    </div>
                  </div>
                </div>
                <div class="subheading mb-5 post__body">
                  <p>
                    In ac felis quis tortor malesuada pretium. Nam at tortor in
                    tellus interdum sagittis. Sed fringilla mauris sit amet
                    nibh. Nullam nulla eros, ultricies sit amet, nonummy id,
                    imperdiet feugiat, pede. Phasellus dolor.
                  </p>

                  <p>
                    Fusce fermentum. Cras ultricies mi eu turpis hendrerit
                    fringilla. Nam eget dui. Fusce a quam. Vestibulum ante ipsum
                    primis in faucibus orci luctus et ultrices posuere cubilia
                    Curae; In ac dui quis mi consectetuer lacinia.
                  </p>

                  <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>

                  <p>
                    Felis bibendum ut tristique et egestas. Consectetur purus ut
                    faucibus pulvinar elementum integer enim neque volutpat. Id
                    aliquet lectus proin nibh nisl condimentum id. At elementum
                    eu facilisis sed. Blandit aliquam etiam erat velit
                    scelerisque in.
                  </p>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <i
                      aria-hidden="true"
                      class="
                        v-icon
                        notranslate
                        mr-2
                        mdi mdi-tag-multiple
                        theme--light
                      "
                    ></i>
                    demo, post
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a
                href="/posts/demo-post"
                class="
                  font-weight-light
                  v-btn v-btn--contained v-btn--router
                  theme--light
                  v-size--default
                  accent
                "
                ><span class="v-btn__content"> Read More </span></a
              >
            </div>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="my-12 v-divider theme--light"
            />
            <h2 class="display-2 font-weight-light secondary--text mb-4">
              Hello world!
            </h2>
            <div class="v-sheet theme--light post--dense">
              <div class="container pa-0">
                <div class="row mb-4">
                  <div class="col">
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-calendar theme--light"
                        style="font-size: 16px"
                      ></i>
                      December 21, 2018
                    </div>
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-account theme--light"
                        style="font-size: 16px"
                      ></i>
                      John Leider
                    </div>
                    <div>
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-chat theme--light"
                        style="font-size: 16px"
                      ></i>
                      Leave a Comment
                    </div>
                  </div>
                </div>
                <div class="subheading mb-5 post__body">
                  <p>
                    Adipiscing enim eu turpis egestas pretium aenean pharetra
                    magna. Sagittis id consectetur purus ut faucibus. Viverra
                    justo nec ultrices dui sapien eget mi.
                  </p>
                </div>
                <!---->
              </div>
            </div>
            <div>
              <a
                href="/posts/hello-world"
                class="
                  font-weight-light
                  v-btn v-btn--contained v-btn--router
                  theme--light
                  v-size--default
                  accent
                "
                ><span class="v-btn__content"> Read More </span></a
              >
            </div>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="my-12 v-divider theme--light"
            />
            <h2 class="display-2 font-weight-light secondary--text mb-4">
              Lorem Ipsum
            </h2>
            <div class="v-sheet theme--light post--dense">
              <div class="container pa-0">
                <div class="row mb-4">
                  <div class="col">
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-calendar theme--light"
                        style="font-size: 16px"
                      ></i>
                      December 20, 2018
                    </div>
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-account theme--light"
                        style="font-size: 16px"
                      ></i>
                      John Leider
                    </div>
                    <div>
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-chat theme--light"
                        style="font-size: 16px"
                      ></i>
                      Leave a Comment
                    </div>
                  </div>
                </div>
                <div class="subheading mb-5 post__body">
                  <p>
                    Adipiscing enim eu turpis egestas pretium aenean pharetra
                    magna. Sagittis id consectetur purus ut faucibus. Viverra
                    justo nec ultrices dui sapien eget mi.
                  </p>
                </div>
                <!---->
              </div>
            </div>
            <div>
              <a
                href="/posts/lorem-ipsum"
                class="
                  font-weight-light
                  v-btn v-btn--contained v-btn--router
                  theme--light
                  v-size--default
                  accent
                "
                ><span class="v-btn__content"> Read More </span></a
              >
            </div>
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="my-12 v-divider theme--light"
            />
            <h2 class="display-2 font-weight-light secondary--text mb-4">
              Great news!
            </h2>
            <div class="v-sheet theme--light post--dense">
              <div class="container pa-0">
                <div class="row mb-4">
                  <div class="col">
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-calendar theme--light"
                        style="font-size: 16px"
                      ></i>
                      December 14, 2018
                    </div>
                    <div class="mr-3">
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-account theme--light"
                        style="font-size: 16px"
                      ></i>
                      John Leider
                    </div>
                    <div>
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-chat theme--light"
                        style="font-size: 16px"
                      ></i>
                      Leave a Comment
                    </div>
                  </div>
                </div>
                <div class="subheading mb-5 post__body">
                  <p>
                    Adipiscing enim eu turpis egestas pretium aenean pharetra
                    magna. Sagittis id consectetur purus ut faucibus. Viverra
                    justo nec ultrices dui sapien eget mi.
                  </p>
                </div>
                <!---->
              </div>
            </div>
            <div>
              <a
                href="/posts/great-news"
                class="
                  font-weight-light
                  v-btn v-btn--contained v-btn--router
                  theme--light
                  v-size--default
                  accent
                "
                ><span class="v-btn__content"> Read More </span></a
              >
            </div>
          </div>
        </div>
      </section>
    </section>
  </v-main>
</template>
<script>
import TripItem from "./TripItem.vue";
export default {
  components: {
    TripItem,
  },
  data() {
    return {
      items: Array.from(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        function mapFn(element) {
          return { title: element, subtitle: element, headline: element };
        }
      ),
      labels: ["all", "notcompleted", "completed", "running"],
    };
  },
};
</script>